import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Meet Cyborg Fellaz'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <br></br>
            <div className={splitClasses}>
            
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>What are Cyborg Fellaz?
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="100" data-reveal-container=".split-item">
                  Cyborg Fellaz is a collection of algorithmically generated cyborg face characters designed by&nbsp;
                  <a href="https://twitter.com/LiniMestar" target="_blank" rel="noopener noreferrer">Lini Mestar</a>
                  &nbsp;and minted as NFTs on the Polygon blockchain. The first generation of 10K Cyborg Fellaz will be constructed from variety of outfits, faces, attachments, components, bags, arms, and colors.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br></p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-scale-up',
                    imageFill && 'split-item-image'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                  <div style={imgOddStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-01.png')}
                      alt="Features split top 01"
                      width={700}
                      height={583} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-container=".split-item">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>Who is behind Cyborg Fellaz?
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="100" data-reveal-container=".split-item">
                    The Cyborg Fellaz team is Pedro Benitez in marketing & PR. David Hanna as head artist.&nbsp;
                  <a href="https://twitter.com/LiniMestar" target="_blank" rel="noopener noreferrer">Lini Mestar</a>
                  &nbsp;is the artist behind Cyborg Fellaz.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br></p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-scale-up',
                    imageFill && 'split-item-image'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div style={imgEvenStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-04.png')}
                      alt="Features split top 02"
                      width={700}
                      height={583} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-container=".split-item">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>What’s an NFT?
                  </h3>
                  <p className="m-0 reveal-from-right" data-reveal-delay="100" data-reveal-container=".split-item">
                  NFT is an abbreviation for "non-fungible token," which implies it's a one-of-a-kind, digital asset with blockchain-managed ownership that users can buy, possess, and trade. The primary function of some NFT is to be digital art. However, they can also provide extra benefits like as exclusive access to websites, event tickets, gaming goods, and physical object ownership records. Consider it a one-of-a-kind work of art that also serves as a "members-only" card. This is how Cyborg Fellaz works.
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br></p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-scale-up',
                    imageFill && 'split-item-image'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                  <div style={imgEvenStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-02.png')}
                      alt="Features split top 02"
                      width={700}
                      height={583} />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>How do I NFT?
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="100" data-reveal-container=".split-item">
                  New to NFTs? No worries, here are some steps on what you need to do to get your Cyborg Fellaz.
									<ol role="listitem">
                    <li>Download the&nbsp;
                      <a target="_blank" href="https://metamask.io/" rel="noopener noreferrer">metamask.io</a>
                      &nbsp;extension for the Chrome/Brave browser or app on mobile. This will allow you to make purchases with Polygon and can be found in the extensions tab. If you are on mobile, you must use the Metamask App Browser
                    </li>
                    <li>You can purchase Polygon through the Metamask Wallet using Wyre or Send Polygon from an exchange like&nbsp;
                      <a target="_blank" href="https://crypto.com/" rel="noopener noreferrer">crypto.com</a> or&nbsp;
                      <a target="_blank" href="https://coinbase.com/" rel="noopener noreferrer">Coinbase</a>.
                    </li>
                    <li>Click on&nbsp;
                      <strong>Connect</strong> at the top of the page and connect your Metamask. Once joined, you will be able to purchase the NFTs in the mint section. You will be prompted to sign your transaction. FYI, there will be a fee associated with every transaction related to gas prices.
                    </li>
                    <li>Once you have made your purchase, your Cyborg Fellaz NFTs will be viewable in your wallet and on&nbsp;
                      <a href="https://opensea.io/collection/cyborg-fellaz" rel="noopener noreferrer" target="_blank">OpenSea</a>.
                    </li>
                  </ol>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br></p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile illustration-element-05 reveal-scale-up',
                    imageFill && 'split-item-image'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    src={require('./../../assets/images/features-split-image.svg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                  <div style={imgOddStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-03.png')}
                      alt="Features split top 03"
                      width={700}
                      height={583} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgOddStyle = {
  position: 'absolute',
  width: '132.57%',
  maxWidth: '132.57%',
  top: '-20.2%',
  left: '-16.1%'
}

const imgEvenStyle = {
  position: 'absolute',
  width: '132.57%',
  maxWidth: '132.57%',
  top: '-20.2%',
  left: '-16.6%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;