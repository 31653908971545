import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Team from '../components/sections/Team';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit imageFill className="illustration-section-01" />
        {/* <Clients topDivider bottomDivider /> */}
        {/* <FeaturesTiles /> */}
        <FeaturesSplit invertMobile imageFill />
        {/* <Testimonial topDivider /> */}
        {/* <Team /> */}
        {/* <Pricing hasBgColor topDivider pricingSwitcher /> */}
        <Roadmap topDivider />
        <Cta hasBgColor invertColor topDivider bottomDivider split />
      </React.Fragment>
    );
  }
}

export default Home;